export class AppMeta {
    public minimumSupportedVersion: AppVersion;
    public currentVersion: AppVersionByPlatform;
    public featureFlags: AppMetaFeatureFlags;
    public generalNotice: GeneralNotice | null;

    constructor(initialValues?: Partial<AppMeta>) {
        this.minimumSupportedVersion = initialValues?.minimumSupportedVersion ?? DEFAULT_VERSION;
        this.currentVersion = {
            android: initialValues?.currentVersion?.android ?? DEFAULT_VERSION,
            ios: initialValues?.currentVersion?.ios ?? DEFAULT_VERSION,
            web: initialValues?.currentVersion?.web ?? DEFAULT_VERSION,
        };
        this.featureFlags = {
            backgroundGeolocation: initialValues?.featureFlags?.backgroundGeolocation ?? true,
            banking: initialValues?.featureFlags?.banking ?? false,
            orderRequests: initialValues?.featureFlags?.orderRequests ?? false,
            campaigns: initialValues?.featureFlags?.campaigns ?? false,
            maschinenrings: initialValues?.featureFlags?.maschinenrings ?? false,
            incomingCreditNotes: initialValues?.featureFlags?.incomingCreditNotes ?? false,
        };
        this.generalNotice = initialValues?.generalNotice ?? null;
    }
}

export type AppVersion = {
    major: number;
    minor: number;
    patch: number;
};

export type AppVersionByPlatform = {
    android: AppVersion;
    ios: AppVersion;
    web: AppVersion;
};

export type AppMetaFeatureFlags = {
    backgroundGeolocation: boolean;
    banking: boolean;
    orderRequests: boolean;
    campaigns: boolean;
    maschinenrings: boolean;
    incomingCreditNotes: boolean;
};

type GeneralNotice = {
    header: string;
    content: string;
};

const DEFAULT_VERSION: AppVersion = { major: 0, minor: 0, patch: 0 };
